@tailwind base;
@tailwind components;
@tailwind utilities;

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

@layer utilities {
  .inherit {
    display: inherit !important;
    grid-template-columns: inherit !important;
    grid-template-rows: inherit !important;
    grid-column: inherit !important;
  }
}
